import { Stack } from "@mui/material";

const Header = () => {
    return (
        <Stack direction={"row"} sx={{ mt: 1 }} justifyContent={"start"}>
            <img src="../static/logo.svg" alt="logo" width={100} />
        </Stack>
    );
}

export default Header;