import { PageableContentResultDto } from "../../../types/types";

export type ItemSearchResultProps = {
    data: PageableContentResultDto
}

const ItemSearchResult = ({ data }: ItemSearchResultProps) => {
    return (
        <li
            style={{
                color: "white",
                marginBottom: "28px"
            }}
        >
            <a
                target="_blank"
                href={data.url}
                style={{
                    display: "flex",
                    padding: '2px'
                }}
                // Выдаёт без этого - warming, можно убрать, при вёрстке я его поставил, так как раздражал
                rel="noreferrer"
            >
                <img src={data.faviconUrl}
                    width={17}
                    height={17}
                    alt="iconrequest"
                    style={{
                        height: "17px",
                        margin: "2px 18px 5px 0"
                    }}
                />
                <h2
                    style={{
                        fontWeight: 700,
                        fontSize: "17px",
                        lineHeight: "22px"
                    }}
                >
                    {data.title}
                </h2>
            </a>
            <a href={data.url}
                target="_blank"
                role="text"
                style={{
                    fontWeight: 200,
                    fontSize: "12px",
                    lineHeight: "22px",
                    opacity: 0.3,
                    marginLeft: "38px"
                }}
                // Тоже самое, ликвидация раздражающего warming
                rel="noreferrer"
            >
                {data.title}
            </a>
            <p
                style={{
                    maxWidth: "650px",
                    fontWeight: 300,
                    fontSize: "17px",
                    lineHeight: "22px",
                    marginLeft: "38px"
                }}
            >
                {data.shortContent}
            </p>
        </li>
    );
}


export default ItemSearchResult;