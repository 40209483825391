import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { FastBlockInfoDto } from '../../types/types';

const defaultFastBlockInfo: FastBlockInfoDto = {
	weather: { temperature: 25, cloud_cover: 0, precipitation_type: 0 },
	expensive_condition: 0,
	rates: [
		{ code: 'USD', value: 85.7 },
		{ code: 'EUR', value: 92.83 },
	], // max 2 items
	location: 'Москва',
};

const FastBlockInfo = () => {
	const [fastInfo, setFastInfo] =
		useState<FastBlockInfoDto>(defaultFastBlockInfo);

	return (
		<Box>
			<Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						paddingLeft: '12px',
						paddingRight: '12px',
					}}
				>
					<span>Последние новости</span>
					<Box sx={{ display: 'flex' }}>
						<span style={{ marginRight: '12px' }}>Курс валют</span>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							{fastInfo.rates.map(item => (
								<span>
									{item.code} {item.value}
								</span>
							))}
						</Box>
					</Box>
				</Box>
			</Typography>
		</Box>
	);
};

export default FastBlockInfo;
