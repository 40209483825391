import * as React from 'react';
import { useState } from "react";
import { QueryResultDto } from "../types/types";
import { createRequest } from "../api/controllers/web-controller";
import EmptySearchBlock from "./search/EmptySearchBlock";
import RichSearchBlock from "./search/RichSearchBlock";
import { mockDataSearch } from '../mockDataSearch';


const SearchBlock = () => {

    const [newSession, setNewSession] = useState<boolean>(true);
    const [query, setQuery] = React.useState<string>('');
    const [result, setResult] = useState<QueryResultDto>();

    const handlerSubmit = () => {
        createRequest({ query: query }).then(response => {
            setResult(response.data);
            setNewSession(false);
        }).catch(err => {
            console.log(err);
            alert("Произошла ошибка. Мы уже чиним =)");
            // TODO удалить в бою
            setNewSession(false);
        });
    }

    /**
     * Отображение в переменной значения value input
     * @param elm input
     */
    const changeLink = (elm: any) => {
        setQuery(elm.target.value);
    }

    const setNewSessionAsFalse = () => {
        setNewSession(false);
    }

    return (
        newSession ? <EmptySearchBlock onChange={changeLink} handlerSubmit={handlerSubmit} />
            : <RichSearchBlock result={result} submitFn={handlerSubmit} changeFn={changeLink} />
    )
}

export default SearchBlock;