import { Container } from "@mui/material";


const Footer = () => {
    return (
        <Container sx={{
            color: "white",
            textAlign: "center",
            mt: 2
        }}>
            <ul>
                <li>Условия использования</li>
                <li>© 2023–2024 «RELL TECH»</li>
            </ul>
        </Container>)
}

export default Footer;