import { Container, Grid } from '@mui/material';
import { useState } from 'react';
import FastBlockInfo from './FastBlockInfo';
import Tabs from './Tabs';
import { Button, StyledInput } from './styled';

type EmptySearchBlockProps = {
	onChange: (elm: any) => void;
	handlerSubmit: () => void;
};

const EmptySearchBlock = ({
    onChange,
    handlerSubmit,
}: EmptySearchBlockProps) => {
    const [inputValue, setInputValue] = useState('');

    const inputChangeHandler = (elm: any) => {
        const value = elm.target.value;
        setInputValue(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handlerSubmit();
        }
    };

    const handleClick = () => {
        if (inputValue.length > 0) {
            handlerSubmit();
        }
    };

    return (
        <Container>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item md={inputValue.length > 0 ? 10 : 12} sm={inputValue.length > 0 ? 10 : 12}>
                    <StyledInput
                        id='url'
                        onChange={inputChangeHandler}
                        onKeyDown={handleKeyDown}
                        label='Искать'
                        variant='outlined'
                        fullWidth
                    />
                </Grid>
                {inputValue.length > 0 && (
                    <Grid item md={2} sm={2}>
                        <Button
                            sx={{ mt: 0, height: 56 }}
                            fullWidth={true}
                            size={'large'}
                            variant='contained'
                            onClick={handleClick}
                        >
                            Поиск
                        </Button>
                    </Grid>
                )}
            </Grid>
            {inputValue.length > 0 && <Tabs />}
            {!inputValue.length && <FastBlockInfo />}
        </Container>
    );
};

export default EmptySearchBlock;