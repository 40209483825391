import { Box, Grid, Stack } from '@mui/material';
import { QueryResultDto } from '../../types/types';
import ItemSearchResult from './element/ItemSearchResult';
import { Button, StyledInput } from './styled';

type RichSearchBlockProps = {
	result: QueryResultDto | undefined;
	submitFn: () => void;
	changeFn: (el: any) => void;
};

const RichSearchBlock = ({
	result,
	submitFn,
	changeFn,
}: RichSearchBlockProps) => {
	return (
		<Stack>
			<Grid container spacing={2} sx={{ mt: 2 }}>
				<Box sx={{ display: 'flex', gap: '10px', width: '100%' }}>
					<StyledInput
						id='url'
						onChange={changeFn}
						onKeyDown={e => {
							if (e.key === 'Enter') submitFn();
						}}
						label='Искать'
						variant='outlined'
						fullWidth
					/>
					<Button
						sx={{ mt: 0, height: 56 }}
						fullWidth={true}
						size={'large'}
						variant='contained'
						onClick={submitFn}
					>
						Поиск
					</Button>
				</Box>
			</Grid>
			<Box>
				{result && result.size > 0 && (
					<ul
						style={{
							marginTop: '50px'
						}}
					>
						{result.content.map((item, index) => (
							<ItemSearchResult key={index} data={item} />
						))}
					</ul>
				)}
				{result == null ||
					(result.size == 0 && <span>Нет результатов по данному запросу</span>)}
			</Box>
		</Stack>
	);
};

export default RichSearchBlock;
