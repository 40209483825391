import { Box, Typography } from '@mui/material';
import { useState } from 'react';

const Tabs = () => {
	const [tabs] = useState<Array<string>>([
		'Поиск',
		'Картинки',
		'Видео',
		'Карты',
	]);

	return (
		<Box>
			<Typography>
				<Box sx={{ display: 'flex', gap: '8px' }}>
					{tabs.map(el => (
						<span>{el}</span>
					))}
				</Box>
			</Typography>
		</Box>
	);
};

export default Tabs;
