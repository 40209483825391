import { Container } from '@mui/material';
import SearchBlock from '../components/SearchBlock';
import AppLayout from '../layouts/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';

const MainPage = () => {
	return (
		<AppLayout>
			<Container
				sx={{
					flexGrow: 1,
					display: 'flex',
					justifyContent: 'space-between',
					margin: '0 auto',
					flexDirection: 'column',
					height: "100vh"
				}}
			>
				<Header />
				<SearchBlock />
				<Footer />
			</Container>
		</AppLayout>
	);
};

export default MainPage;
