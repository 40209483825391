import axios from "axios";
import { getToken } from "../helpers/tokenHelper";

export const $api = axios.create({
    baseURL: `${process.env.REACT_APP_BASEURL}`,
});

export const config = () => {
    return {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Expose-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'auth-token': getToken(),
    }
}

$api.interceptors.response.use(function (response) {
    // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
    // Здесь можете сделать что-нибудь с ответом
    return response;
}, function (error) {
    if (error.code == 'BAD_REQUEST' || error.code == 'FORBIDDEN'
        || error.response.status == 401 || error.response.status == 403) {
        localStorage.removeItem("tokenValue");
        localStorage.removeItem("tokenType");
        window.location.replace("/panel/login");
        return;
    }
    // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
    // Здесь можете сделать что-то с ошибкой ответа
    return Promise.reject(error);
});